<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>新增付款记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">

      <el-row :gutter="30" style="margin-bottom: 30px;">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <span class="title">订单号：</span>
          <span class="text">{{addPurchaseForm.p_code}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">订单金额：</span>
          <span class="text">{{addPurchaseForm.p_price}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">已付款金额：</span>
          <span class="text">{{addPurchaseForm.p_payment_price}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">未付款金额：</span>
          <span class="text">{{addPurchaseForm.p_unpaid_price}}</span>
        </el-col>
      </el-row>
      <el-form  :model="addPurchasePayForm" :rules="addPurchasePayFormRules"
                ref="addPurchasePayFormRef"
                label-width="100px">
        <el-form-item label="付款金额：" prop="pp_payment_price">
          <el-input v-model="addPurchasePayForm.pp_payment_price" @keyup.native="addPurchasePayForm.pp_payment_price = money(addPurchasePayForm.pp_payment_price)" class="pp_payment_price"></el-input>
        </el-form-item>
        <el-form-item label="付款日期：" prop="pp_payment_date">
<!--          <el-input v-model="addPurchaseForm.pp_payment_date" class="pp_payment_price"></el-input>-->
          <el-date-picker
              v-model="addPurchasePayForm.pp_payment_date"
              type="datetime" value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式：" prop="pp_payment_method">
<!--          <el-input v-model="addPurchaseForm.pp_payment_method" class="pp_payment_price" disabled></el-input>-->
          <el-select v-model="addPurchasePayForm.pp_payment_method" class="w280" placeholder="请选择" @change="selectpay(addPurchasePayForm.pp_payment_method)">
            <el-option
                v-for="item in List"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户名称：" prop="pp_account_name" v-if="addPurchasePayForm.pp_payment_method==2">
          <el-input v-model="addPurchasePayForm.pp_account_name" class="pp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="开户银行：" prop="pp_account_bank" v-if="addPurchasePayForm.pp_payment_method==2">
          <el-input v-model="addPurchasePayForm.pp_account_bank" class="pp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="银行账号：" prop="pp_bank_account" v-if="addPurchasePayForm.pp_payment_method==2">
          <el-input v-model="addPurchasePayForm.pp_bank_account" class="pp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="pp_remark">
          <el-input v-model="addPurchasePayForm.pp_remark" class="pp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="支付凭证：" prop="pp_payment_img">
<!--          <el-input v-model="addPurchasePayForm.pp_payment_img" class="pp_payment_price" ></el-input>-->
          <el-upload class="avatar-uploader" :headers="headersObj"
                     :action="action" :show-file-list="false"
                     :on-success="edithandleAvatarSuccess" >
            <div v-if="addPurchasePayForm.pp_payment_imgsee" class="upload-avatar">
              <img  :src="addPurchasePayForm.pp_payment_imgsee">
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div  v-if="addPurchasePayForm.pp_payment_imgsee" class="upload-delete" @click="edithandleRemove">
            <i class="el-icon-close"></i>
          </div>
        </el-form-item>
        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      addPurchaseForm:{},
      List:[
        {id:1,name:'现金'},
        {id:2,name:'银行转账'},
        {id:3,name:'微信'},
        {id:4,name:'支付宝'},
      ],
      addPurchasePayForm:{
        p_id: this.$route.query.editid,
        pp_payment_img:'',
        pp_payment_imgsee:''
      },
      addPurchasePayFormRules:{
        pp_payment_price:[
          {required: true, message: '请输入付款金额', trigger: 'blur'}
        ],
        pp_payment_date:[
          {required: true, message: '请选择付款日期', trigger: 'change'}
        ],
        pp_payment_method:[
          {required: true, message: '请选择支付方式', trigger: 'change'}
        ]

      },
      headersObj:{
        Authorization:'Bearer ' + window.sessionStorage.getItem('token')
      },
      action:process.env.VUE_APP_BASE_API+'/upload/category',


    }
  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`purchase/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      // this.querInfo.s_id=res.data.s_id
      // this.getGoodsList(this.querInfo)
      // this.adddisabled=false

    },
    addgo(){
      this.$router.go(-1)
    },
    //提交form表单
    async addupPurchase(){
      // console.log(this.addPurchasePayForm)
      this.$refs.addPurchasePayFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('purchase/payment', this.addPurchasePayForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    //监听图片上传成功的事件
    edithandleAvatarSuccess(response){
      // this.addPurchasePayForm.pp_payment_img=response.data.saveurl
      // this.addPurchasePayForm.img=response.data.saveurl
      this.addPurchasePayForm.pp_payment_img=response.data.saveurl
      this.addPurchasePayForm.pp_payment_imgsee=response.data.url
    },
    //编辑分类的图片删除
    edithandleRemove(){
      this.addPurchasePayForm.pp_payment_img=''
      this.addPurchasePayForm.pp_payment_imgsee=''
    },
    //判断支付方式
    selectpay(id){
      if(id==2){
        this.addPurchasePayForm.pp_account_name=this.addPurchaseForm.s_account_name
        this.addPurchasePayForm.pp_account_bank=this.addPurchaseForm.s_deposit_bank
        this.addPurchasePayForm.pp_bank_account=this.addPurchaseForm.s_account_bank
      }else{
        this.addPurchasePayForm.pp_account_name=''
        this.addPurchasePayForm.pp_account_bank=''
        this.addPurchasePayForm.pp_bank_account=''
      }
    }
  },
  created(){
    this.getpurchaseList()
  }
}
</script>
<style lang="less" scoped>
.el-col{
  display: flex;
}
.pirce{
  width: 200px;
}
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
.el-input{
  width: 280px;
}
.w280{
  width: 280px;
}
</style>
